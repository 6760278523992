import axios from 'axios';
import { Session } from './DataController';
class POSUser {
    constructor() {
        return {
            strUsuario: '',
            strTerminal: ''
        }
    }
}

class POSUserFunctions {
    async login(intEmpresa, user, pass, idm){
        const session = new Session();

        const response = await axios.get("POS_DataUser/" + intEmpresa + "/" + user + "/" + pass + "/" + idm, {
            headers: session
        });
        return response.data;
    }
    async loginSinRegister(intEmpresa, user, pass){
        const session = new Session();

        const response = await axios.post("POS_Login/" + intEmpresa, {
            strUsuario: user,
            strPassword: pass
        }, {
            headers: session
        });
        return response.data;
    }
    async terminales(intEmpresa, user){
        const session = new Session();

        const response = await axios.get("POS_UsuarioTerminales/" + intEmpresa + "/" + user, {
            headers: session
        });
        return response.data;
    }
    async cajero(intEmpresa, user){
        const session = new Session();

        const response = await axios.get("POS_UsuarioCajero/" + intEmpresa + "/" + user, {
            headers: session
        });
        return response.data;
    }
    //Registro del POSRegister
    async register(user, pass, intEmpresa){
        const session = new Session();

        const response = await axios.get("POS_DataUserAutoriza/" + intEmpresa + "/" + user + "/" + pass, {
            headers: session
        });
        return response.data;
    }
    //Salir de sesion
    async logout(user, intEmpresa, idm){
        const session = new Session();

        const response = await axios.get("POS_DataCierreSesion/" + intEmpresa + "/" + user + "/" + idm, {
            headers: session
        });
        return response.data;
    }
    //Validacion de firma de usuario
    async firma(user, pass, version, parametro, intEmpresa){
        const session = new Session();

        const response = await axios.get("POS_DataUserFirma/" + intEmpresa + "/" + user + "/" + pass + "/" + version + "/" + parametro, {
            headers: session
        });
        return response.data;
    }
    async mesero(intEmpresa, strUsuario){
        const session = new Session();

        const response = await axios.get("POS_DataUsuarioMesero/" + intEmpresa + "/" + strUsuario, {
            headers: session
        });
        return response.data;
    }
    async autorizaDescuento(intEmpresa, user){
        const session = new Session();

        const response = await axios.post("POS_AutorizaDescuento/" + intEmpresa, user, {
            headers: session
        });
        return response.data;
    }
    async autorizaCancelacion(intEmpresa, user){
        const session = new Session();

        const response = await axios.post("POS_AutorizaCancelacion/" + intEmpresa, user, {
            headers: session
        });
        return response.data;
    }
    async autorizaSeparacion(intEmpresa, user){
        const session = new Session();

        const response = await axios.post("POS_AutorizaSeparacion/" + intEmpresa, user, {
            headers: session
        });
        return response.data;
    }
    async terminal(){
        const session = new Session();

        const response = await axios.get("POS_DataTerminal", {
            headers: session
        });
        return response.data;
    }

}

export { POSUser, POSUserFunctions };