<template>
  <v-container fluid>
    <Loader v-model="loading" />
    <v-row no-gutters>
      <v-col
        ><v-text-field
          :readonly="disabled"
          dense
          outlined
          rounded
          v-model="localValue.strTelefono"
          label="Telefono"
          append-outer-icon="mdi-account-search"
          @click:append-outer="buscar()"
        >
        </v-text-field
      ></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        ><v-select
          dense
          :items="repartidores"
          outlined
          rounded
          item-text="strNombre"
          item-value="intEmpleado"
          label="Repartidor"
          v-model="localValue.intEmpleado"
        >
        </v-select
      ></v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :readonly="disabled"
          dense
          rounded
          outlined
          v-model="localValue.strNombre"
          label="Nombre"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :readonly="disabled"
          dense
          rounded
          outlined
          v-model="localValue.strDireccion"
          label="Calle y Numero"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-combobox
          :readonly="disabled"
          item-text="strColonia"
          item-value="strColonia"
          :items="colonias"
          placeholder="Buscar..."
          label="Colonia"
          outlined
          rounded
          :return-object="false"
          dense
          v-model="localValue.strColonia"
        >
        </v-combobox>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :readonly="disabled"
          dense
          rounded
          outlined
          v-model="localValue.strEntreCalle"
          label="Entre Calles"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :readonly="disabled"
          dense
          rounded
          outlined
          v-model="localValue.strReferencia"
          label="Referencias"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :readonly="disabled"
          dense
          outlined
          rounded
          v-model="localValue.strPoblacion"
          label="Municipio"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :readonly="disabled"
          dense
          rounded
          outlined
          v-model="localValue.strTelefono2"
          label="EXT, Telefono 2"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!disabled" no-gutters>
      <v-col>
        <v-btn rounded color="primary" block @click="save()">Guardar</v-btn>
      </v-col>
    </v-row>
    <BuscarCliente
      :colonias="colonias"
      @clienteSeleccionado="seleccionarCliente"
      v-model="buscarCliente"
    />
  </v-container>
</template>

<script>
import { Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import Loader from "./Loader.vue";
import { POSEncFunctions } from "../controllers/POSController.js";
import BuscarCliente from "./BuscarClienteComponent.vue";

export default {
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    repartidores: {
      type: Array,
      default: () => [],
    },
    colonias: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      session: new Session(),
      loading: false,
      localValue: this.value,
      ordenFunctions: new POSEncFunctions(this.$config.api_backend_route),
      buscarCliente: {
        isVisible: false,
        data: [],
      },
    };
  },
  components: {
    Loader,
    BuscarCliente,
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        if (this.localValue.intCliente > 0) {
          await this.ordenFunctions.editarCliente(
            this.localValue,
            this.session.empresa,
            this.session.usuario,
            this.session.terminal
          );
          this.$toast.success("Cliente actualizado con exito", new Toast());
        } else {
          let response = await this.ordenFunctions.saveCliente(
            this.localValue,
            this.session.empresa,
            this.session.usuario,
            this.session.terminal
          );
          this.localValue.intCliente = response.intCliente;
          this.$toast.success("Cliente agregado con exito", new Toast());
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    async buscar() {
      if (!this.disabled) {
        this.loading = true;
        try {
          this.buscarCliente.data = await this.ordenFunctions.clientes(
            this.session.empresa,
            this.localValue.strTelefono
          );
          this.buscarCliente.isVisible = true;
        } catch (e) {
          this.$toast.error(ErrorWeb(e), new Toast());
        } finally {
          this.loading = false;
        }
      }
    },
    seleccionarCliente(cliente) {
      this.buscarCliente.isVisible = false;
      this.localValue = cliente;
    },
  },
};
</script>

<style></style>
