<template>
  <v-app>
    <v-expand-transition>
      <router-view />
    </v-expand-transition>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.theme--dark.v-application {
  background-color: var(--v-background-base, #eeeeee) !important;
}
.theme--light.v-application {
  background-color: var(--v-background-base, #eeeeee) !important;
}

#app {
  background-image: url("./assets/tpv.jpg");
  background-size: cover;
  overflow: hidden;
}
</style>
