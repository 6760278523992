<template>
  <v-dialog v-model="localValue.isVisible" width="750">
    <Loader v-model="loading" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cortesia</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.usuario"
                        :rules="[() => !!usuario.usuario || 'Llene este campo']"
                        append-icon="mdi-account"
                        label="Usuario"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.password"
                        :rules="[() => !!usuario.password || 'Llene este campo']"
                        append-icon="mdi-shield-lock"
                        type="password"
                        label="Password"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" dark block @click="guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Usuario, Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSDetFunctions } from "../controllers/POSController";

export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      loading: false,
      detFunctions: new POSDetFunctions(),
      session: new Session(),
      usuario: new Usuario(),
    };
  },
  components: {
    Loader,
  },
  methods: {
    async guardar() {
      this.loading = true;
      try {
        //*Validamos usuario
        const validacion = await this.usuario.validate_cancelacion();
        if (!validacion) throw "No se pudo validar el usuario, no tiene permisos";

        await this.detFunctions.cortesia(
          this.localValue.POSEnc.intPOSEnc.toString(),
          this.usuario.usuario
        );
        //*Limpiamos
        this.localValue.isVisible = false;
        this.usuario = new Usuario();
        this.$emit("guardar");
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
