<template>
  <v-dialog width="500px" v-model="localValue.isVisible">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Terminales</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row v-if="isVisibleCajero">
          <v-col>
            <v-checkbox v-model="apertura" label="Apertura de Caja"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p>{{ message }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :disabled="disableTerminal"
              label="Terminal"
              :items="terminales"
              outlined
              item-text="strNombre"
              item-value="strIdTerminal"
              v-model="localValue.terminal"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn block color="primary" @click="entrar()"> Entrar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { POSRegisterFunctions } from "../controllers/RegisterController.js";
import { Toast } from "../controllers/DataController.js";
import { POSRegister } from "../controllers/POSController";

export default {
  props: {
    value: {
      required: true,
    },
    empresa: {
      type: Number,
      default: 0,
    },
    cajero: {
      type: Object,
      default: () => {},
    },
    usuario: {
      type: Object,
      default: () => {},
    },
    ultimoRegister: {
      type: Object,
      default: null,
    },
    terminales: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    entrar() {
      if (this.localValue.terminal == "") {
        this.$toast.error("Seleccione una terminal", new Toast());
      } else {
        let cajero =
          this.message ==
          "Usted abrio una caja en esta terminal recientemente, entrara en modo CAJERO";
        this.$emit("entrar", {
          cajero: cajero,
          apertura: this.apertura,
          ...this.localValue,
        });
      }
    },
  },
  data() {
    return {
      disableTerminal: false,
      registerActual: new POSRegister(),
      registerFunctions: new POSRegisterFunctions(this.$config.api_backend_route),
      message: "",
      loading: false,
      apertura: this.cajero.intCajero == 1,
      isVisibleCajero: true,
      localValue: this.value,
    };
  },
  watch: {
    loading(newValue) {
      this.$emit("loading", newValue);
    },
    localValue: {
      handler(newValue) {
        this.$emit("input", newValue);
      },
      deep: true,
    },
    async ["localValue.terminal"](newVal) {
      this.loading = true;
      try {
        if (newVal) {
          this.registerActual = await this.registerFunctions.register_actual(
            this.empresa,
            newVal
          );
          if (this.registerActual.intPOSRegister > 0) {
            this.isVisibleCajero = false;
            if (
              this.registerActual.strUsuarioPOS.toUpperCase() ==
              this.usuario.usuario.toUpperCase()
            ) {
              this.apertura = true;
              this.message =
                "Usted abrio una caja en esta terminal recientemente, entrara en modo CAJERO";
            } else {
              this.message =
                "Ya existe una caja abierta en esta terminal, entrara en modo COMANDERO";
              this.apertura = false;
            }
          } else {
            this.isVisibleCajero = true;
          }
        }
      } catch (e) {
        alert(e);
      } finally {
        this.loading = false;
      }
    },
    ["localValue.isVisible"](newVal) {
      if (!newVal) {
        this.registerActual = new POSRegister();
        this.isVisibleCajero = true;
        this.apertura = false;
        this.localValue.terminal = "";
        this.disableTerminal = false;
        this.$emit("change_ultimo");
      } else {
        this.apertura = this.cajero.intCajero == 1;
        //* Verificamos si tiene registros actuales, no le dejamos hacer nada, SOLO CAJERO
        if (this.ultimoRegister) {
          if (this.ultimoRegister.intPOSRegister > 0) {
            //* Si todavia tiene esas opciones en la seleccion lo cambiamos
            let result = this.terminales.find(
              (x) => x.strIdTerminal == this.ultimoRegister.strIdTerminal
            );
            if (result) {
              this.localValue.terminal = this.ultimoRegister.strIdTerminal;
              this.disableTerminal = true;
            }
          }
        }
      }
    },
    value: {
      handler(newValue) {
        this.localValue = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
