<template>
  <div>
    <Loader v-model="loading" />
    <Keyboard v-model="keyboard" :layout="layout" />

    <PrinterDialog
      :terminal="terminal"
      @closed="despuesImpresion"
      @finished="despuesImpresion"
      v-model="printer"
      :impresoras="impresoras"
    />

    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card shaped class="justify-center rounded-xl">
            <v-toolbar dark color="primary">
              <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
              <v-spacer></v-spacer>
              <v-toolbar-title v-if="opcion == 1">Registro Caja</v-toolbar-title>
              <v-toolbar-title v-else-if="opcion == 2">Arqueo</v-toolbar-title>
              <v-toolbar-title v-else-if="opcion == 3">Cierre Turno</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  dense
                  label="Billetes de 500"
                  outlined
                  rounded
                  v-model="registro.dblDenominacion500"
                  @keypress="isNumber($event)"
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 200"
                  outlined
                  :disabled="opcion == 3"
                  @click="showKeyboard($event, 'numeric')"
                  v-model="registro.dblDenominacion200"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 100"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblDenominacion100"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 50"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblDenominacion50"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Billetes de 20"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblDenominacion20"
                  @keypress="isNumber($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Monedas"
                  outlined
                  @click="showKeyboard($event, 'numeric')"
                  :disabled="opcion == 3"
                  v-model="registro.dblMonedas"
                  @keypress="isFloat($event)"
                  rounded
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  v-if="opcion == 3"
                  dense
                  label="Otros Pagos"
                  outlined
                  v-model="registro.dblOtrosPagos"
                  @keypress="isFloat($event)"
                  rounded
                  readonly
                  type="number"
                  min="0"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Comentario"
                  @click="showKeyboard($event, 'compact')"
                  v-model="registro.strComentario"
                  outlined
                  id="comentario"
                  ref="nota"
                  rounded
                  type="text"
                ></v-text-field>
                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      readonly
                      v-model="totalRegistrado"
                      label="Total"
                      outlined
                      rounded
                      type="text"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-btn rounded block color="primary" @click="registrarCaja()"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
var numeral = require("numeral");
var Decimal = require("decimal.js");
import Cookies from "js-cookie";
import Keyboard from "../components/Keyboard.vue";

import { POSRegister, POSRegisterFunctions } from "../controllers/RegisterController.js";
import { POSTerminalFunctions } from "../controllers/POSController.js";
import { isNumber, isFloat } from "../js/utilities";
// import DialogUI from "../components/DialogComponent.vue";
import Loader from "../components/Loader.vue";
import PrinterDialog from "../components/PrinterDialogComponent.vue";
import { Data, Dialog, Toast, Session, ErrorWeb } from "../controllers/DataController.js";

export default {
  data() {
    return {
      keyboard: {
        visible: false,
        input: null,
      },
      layout: "numeric",
      session: new Session(),
      opcion: this.$route.params.opcion,
      printer: new Dialog(0, 0, "", "", ""),
      impresoras: [],
      nombreTerminal: "",
      corteGlobal: false,
      errorLogin: false,
      registroActual: 0,
      datacierre: null,
      registro: new POSRegister(),
      registerFunctions: new POSRegisterFunctions(),
      terminalFunctions: new POSTerminalFunctions(),
      dataFunctions: new Data(),
      intentos: 1,
      intentosMaximos: 3,
      loading: false,
      terminal: "",
    };
  },
  components: {
    // DialogUI,
    Keyboard,
    PrinterDialog,
    Loader,
  },
  methods: {
    showKeyboard(e, layout) {
      if (this.keyboardSession) {
        if (this.opcion != 3 || e.target.id == "comentario") {
          this.layout = layout;
          this.keyboard.input = e.target;
          this.keyboard.visible = true;
        }
      }
    },
    keyboardMode() {
      this.keyboardSession = !this.keyboardSession;
      let session = new Session();
      session.setKeyboard(this.keyboardSession);
    },
    goBack() {
      if (this.opcion == 1) this.$router.push("/Login");
      else if (this.opcion == 2) this.$router.push("/Main");
      else if (this.opcion == 3) this.$router.push("/Main");
    },
    isNumber: isNumber,
    isFloat: isFloat,
    convertDateJSON(POSRegister) {
      if (POSRegister.DatFechaInicio.toString().includes("T")) {
        return POSRegister.DatFechaInicio.toString();
      } else {
        let x = POSRegister.DatFechaInicio.split("/");
        let response =
          x[2] + "-" + x[1] + "-" + x[0] + "T" + POSRegister.DatHora + ".511Z";
        return response;
      }
    },
    irLogin() {
      Cookies.remove("userPOS");
      Cookies.remove("empresaPOS");
      Cookies.remove("terminalPOS");
      Cookies.remove("aperturaCaja");
      this.$router.push("/login");
    },
    cerrarSesion() {
      Cookies.remove("userPOS");
      this.$router.push("/login");
    },
    despuesImpresion(event) {
      if (event == "apertura") {
        this.$router.push("/RegistroMesero");
      } else if (event == "arqueo") {
        this.$router.push("/Main");
      } else if (event == "cierre") {
        this.$router.push("/Login");
      }
    },
    async registrarCaja() {
      this.loading = true;
      try {
        // * MCZ -> Agregar comentario obligatorio
        if (this.registro.strComentario == "") {
          this.$refs.nota.focus();
          throw "Favor de agregar un comentario";
        }
        // * MCZ -> Registramos POSRegister
        // ** MCZ ->  1 Apertura
        if (this.opcion == 1) {
          const REGISTER_ACTUAL = await this.registerFunctions.register_actual(
            this.session.empresa,
            this.session.terminal
          );
          if (REGISTER_ACTUAL.intPOSRegister != 0) {
            throw "No se puede crear una nueva apertura, ya existe una en esta caja";
          }
          this.registro.intPOSTipoMovimientoTurno = 1;
          this.registro.strUsuarioPOS = this.session.usuario;
          this.registro.dblImporte = this.totalRegistradoV2;
          let POSRegister = await this.registerFunctions.save(this.registro);

          Cookies.set("POS_REGISTER_CAJA", POSRegister.intPOSRegister, {
            expires: 5,
          });
          POSRegister.strTerminal = this.nombreTerminal.strNombre;
          // *? Verificar si va directo, esto solo es pruebas
          this.printer.event = "apertura";
          this.printer.visible = true;
          this.printer.printable = POSRegister;
          this.printer.after_event = true;
          //          this.$router.push("/registromesero");
        }
        // ** MCZ ->  2 Arqueo
        if (this.opcion == 2) {
          this.registro.intPOSTipoMovimientoTurno = 2;
          this.registro.strUsuarioPOS = this.session.usuario;
          this.registro.intPOSRegisterPadre = this.session.register;
          this.registro.dblImporte = this.totalRegistradoV2;

          let POSRegister = await this.registerFunctions.save(this.registro);
          //await TicketFunctions.ticketArqueo(POSRegisterResult, this.nombreTerminal)
          this.printer.event = "arqueo";
          this.printer.visible = true;
          this.printer.printable = POSRegister;
          this.printer.after_event = true;
        }
        // ** MCZ ->  3 Cierre de turno
        if (this.opcion == 3) {
          this.registro.intPOSTipoMovimientoTurno = 3;
          this.registro.strUsuarioPOS = this.session.usuario;
          this.registro.intPOSRegisterPadre = this.session.register;
          this.registro.dblImporte = this.totalRegistradoV2;

          //* Validaciones de cierre
          this.datacierre = await this.registerFunctions.cierre();

          //* Retiros + Tarjetas = Venta
          const retiros = new Decimal(this.datacierre.dblRetiros);
          const tarjetas = new Decimal(this.registro.dblOtrosPagos);
          const venta = new Decimal(this.datacierre.dblVenta);
          if (retiros.plus(tarjetas).toString() != venta.toString()) {
            if (this.intentos < this.intentosMaximos) {
              const intento = this.intentos;
              this.intentos++;
              throw (
                "Verifique las cantidades, tienen una diferencia. Intentos: " + intento
              );
            }
            //* Guardamos con diferencia y notificamos al usuario
            else {
              this.registro.dblDiferencia =
                this.datacierre.dblVenta -
                (this.datacierre.dblRetiros + this.registro.dblOtrosPagos);
              this.registro.dblImporteEntregado =
                this.datacierre.dblRetiros + this.registro.dblOtrosPagos;
              let POSRegister = await this.registerFunctions.save(this.registro);
              POSRegister.strTerminal = this.nombreTerminal.strNombre;
              POSRegister.pagos = await this.registerFunctions.pagos();
              POSRegister.dblVentas = await this.registerFunctions.ventas();
              this.printer.event = "cierre";
              this.printer.visible = true;
              this.printer.printable = POSRegister;
              this.printer.after_event = true;
              this.session.logout();
            }
          }
          //* Guardamos correctamente
          else {
            this.registro.dblImporte = this.totalRegistradoV2;
            let POSRegister = await this.registerFunctions.save(this.registro);
            POSRegister.strTerminal = this.nombreTerminal.strNombre;
            POSRegister.pagos = await this.registerFunctions.pagos();
            POSRegister.dblVentas = await this.registerFunctions.ventas();
            this.terminal = this.session.terminal;
            this.printer.event = "cierre";
            this.printer.visible = true;
            this.printer.printable = POSRegister;
            this.printer.after_event = true;
            this.session.logout();
          }
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    totalRegistrado() {
      let total =
        this.registro.dblDenominacion500 * 500 +
        this.registro.dblDenominacion200 * 200 +
        this.registro.dblDenominacion100 * 100 +
        this.registro.dblDenominacion50 * 50 +
        this.registro.dblDenominacion20 * 20 +
        parseFloat(this.registro.dblMonedas) +
        parseFloat(this.registro.dblOtrosPagos);
      return numeral(total).format("$0,0.00");
    },
    totalRegistradoV2() {
      let total =
        this.registro.dblDenominacion500 * 500 +
        this.registro.dblDenominacion200 * 200 +
        this.registro.dblDenominacion100 * 100 +
        this.registro.dblDenominacion50 * 50 +
        this.registro.dblDenominacion20 * 20 +
        parseFloat(this.registro.dblMonedas) +
        parseFloat(this.registro.dblOtrosPagos);
      return numeral(total).format("0.00");
    },
  },
  async mounted() {
    this.keyboardSession = new Session().keyboard;
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        if (!this.session.usuario) throw "No existe una sesion abierta";

        this.terminalFunctions.retrieve().then((res) => {
          this.nombreTerminal = res;
        });

        if (this.session.registro) this.registroActual = this.session.registro;

        if (this.opcion == 3) {
          this.datacierre = await this.registerFunctions.cierre();
          this.registro.dblOtrosPagos = this.datacierre.dblTarjetas;
        }

        this.impresoras = await this.dataFunctions.loadImpresoras();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    if (
      this.$route.params.opcion != 1 &&
      this.$route.params.opcion != 2 &&
      this.$route.params.opcion != 3
    ) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
