var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Loader',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Editar ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","label":"Referencia"},model:{value:(_vm.ordenFunctions.strReferencia),callback:function ($$v) {_vm.$set(_vm.ordenFunctions, "strReferencia", $$v)},expression:"ordenFunctions.strReferencia"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":function($event){_vm.edit = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.editConfirm()}}},[_vm._v(" Aceptar ")]),_c('v-spacer')],1)],1)],1),_c('v-app-bar',{attrs:{"app":""}},[_c('v-app-bar',{attrs:{"app":"","color":"primary","dark":""}},[_c('v-icon',{on:{"click":_vm.goBack}},[_vm._v("mdi-keyboard-backspace")]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Corte de Vouchers")]),_c('v-spacer'),_c('v-icon',[_vm._v("mdi-account-circle")]),_vm._v(" "+_vm._s(_vm.session.usuario.toUpperCase())+" ")],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Informacion ")]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"x-large":"","block":"","color":"primary"},on:{"click":function($event){return _vm.confirmar()}}},[_vm._v(" Confirmar ")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.results},scopedSlots:_vm._u([{key:"item.dblImporte",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isMoney(item.dblImporte))+" ")]}},{key:"item.dblTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isMoney(item.dblTotal))+" ")]}},{key:"item.strNombre",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.strNombre.substring(3, item.lenght))+" ")]}},{key:"item.intPOSEncPago",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }