<template>
  <v-dialog
    v-model="localValue.visible"
    width="500px"
    persistent
    transition="dialog-bottom-transition"
  >
    <Loader v-model="loading" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn :loading="loading" icon dark @click="localValue.visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Imprimir</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <div class="row">
          <div class="col">
            <label>Seleccione una impresora para realizar la accion</label>
          </div>
        </div>
        <div class="row" v-for="impresora in impresoras" :key="impresora.name">
          <div class="col">
            <v-btn rounded color="primary" block @click="imprimir(impresora)">
              <v-icon left dark>mdi-printer</v-icon>
              {{ impresora.name }}
            </v-btn>
          </div>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import Loader from "./Loader.vue";
import { ErrorWeb, Session, Toast } from "../controllers/DataController.js";
import { POSLogImpresion } from "../controllers/POSController.js";
export default {
  props: {
    impresoras: {
      type: Array,
      default: () => [],
    },
    terminal: {
      type: String,
      default: "",
    },
    posPrinter: {
      type: String,
      default: "",
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      impresora: 0,
      loading: false,
      log: new POSLogImpresion(new Session(), this.$config.api_backend_route),
    };
  },
  components: {
    Loader,
  },
  methods: {
    async imprimir(impresora) {
      this.loading = true;
      try {
        this.localValue.printable.POSPrinter = impresora;
        this.localValue.printable.ticket = this.$config.ticket;
        //*PREPARACION
        if (this.localValue.event == "preparacion") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Preparacion",
              this.localValue.printable
            );
            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              await log.Complete(this.localValue.log.intPOSLogImpresion);

              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Preparacion";
            this.log.strVersion = this.localValue.printable.intPOSTipoServicio.toString();
            this.log.strDescripcion =
              "ORDEN " +
              this.localValue.printable.intOrden +
              " DE USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            this.log.intPOSEnc = this.localValue.printable.intPOSEnc;
            await this.log.Save();
            throw ie;
          }
        } else if (this.localValue.event == "cocina") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Cocina",
              this.localValue.printable
            );
            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              await log.Complete(this.localValue.log.intPOSLogImpresion);

              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Cocina";
            this.log.strDescripcion =
              "ORDEN " +
              this.localValue.printable.intOrden +
              " DE USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            this.log.intPOSEnc = this.localValue.printable.intPOSEnc;
            await this.log.Save();
            throw ie;
          }
        } else if (this.localValue.event == "pago") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Pago",
              this.localValue.printable
            );

            //* Al pagar se tiene que abrir la caja solo si tiene EFECTIVO
            let efectivo = false;
            this.localValue.printable.posEncPago.forEach((pago) => {
              if (!efectivo) {
                efectivo = pago.strNombre.toUpperCase().includes("EFECTIVO");
              }
            });

            if (efectivo) {
              await axios.post(
                impresora.url + "/POSPrinter/CashDrawer",
                this.localValue.printable.POSPrinter
              );
            }

            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              await log.Complete(this.localValue.log.intPOSLogImpresion);

              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Pago";
            this.log.strVersion = this.localValue.printable.intPOSTipoServicio.toString();
            this.log.strDescripcion =
              "ORDEN " +
              this.localValue.printable.intOrden +
              " DE USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            this.log.intPOSEnc = this.localValue.printable.intPOSEnc;
            await this.log.Save();
            throw ie;
          }
        } else if (this.localValue.event == "apertura") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Apertura",
              this.localValue.printable
            );
            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              await log.Complete(this.localValue.log.intPOSLogImpresion);
              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Apertura";
            this.log.intTipoMovimientoTurno = 1;
            this.log.strDescripcion =
              "APERTURA DEL USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            await this.log.Save();
            throw ie;
          }
        } else if (this.localValue.event == "prepago") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Prepago",
              this.localValue.printable
            );
            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              await log.Complete(this.localValue.log.intPOSLogImpresion);
              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Prepago";
            this.log.intTipoMovimientoTurno = 1;
            this.log.strDescripcion =
              "ORDEN " +
              this.localValue.printable.intOrden +
              " DE USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            await this.log.Save();
            throw ie;
          }
        } else if (this.localValue.event == "arqueo") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Arqueo",
              this.localValue.printable
            );
            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              await log.Complete(this.localValue.log.intPOSLogImpresion);

              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Arqueo";
            this.log.intTipoMovimientoTurno = 2;

            this.log.strDescripcion =
              "ARQUEO DEL USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            this.log.intPOSRegister2 = this.localValue.printable.intPOSRegister;
            await this.log.Save();
            throw ie;
          }
        } else if (this.localValue.event == "cierre") {
          try {
            await axios.post(
              impresora.url + "/POSPrinter/Cierre",
              this.localValue.printable
            );
            if (this.localValue.log) {
              //* Guardamos el log
              let log = new POSLogImpresion(new Session());
              log.intPOSLogImpresion = this.localValue.log.intPOSLogImpresion;
              await log.Complete(this.localValue.log.intPOSLogImpresion);
              this.$emit("log", this.localValue.log.intPOSLogImpresion);
            }
          } catch (ie) {
            this.log.intTipoMovimientoTurno = 3;
            this.log.intEmpresa = this.localValue.printable.intEmpresa;
            this.log.intSucursal = this.localValue.printable.intSucursal;
            this.log.strTerminal = this.terminal;
            this.log.strUsuarioAlta = this.localValue.printable.strUsuarioPOS;

            this.log.strError = ErrorWeb(ie);
            this.log.strTipoTicket = "Arqueo";
            this.log.strDescripcion =
              "ARQUEO DEL USUARIO " +
              this.localValue.printable.strUsuarioPOS.toUpperCase();
            this.log.intPOSRegister2 = this.localValue.printable.intPOSRegister;
            await this.log.Save();
            throw ie;
          }
        }
        this.localValue.visible = false;
        this.$emit("finished", this.localValue.event);
      } catch (e) {
        this.$toast.error(
          "Error de impresora. Verificar con area de sitemas",
          new Toast()
        );
        this.$emit("error", this.localValue.event);
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    async ["localValue.visible"](newValue) {
      if (!newValue) {
        this.$emit("closed", this.localValue.event);
      } else {
        //* Se acaba de abrir
        let config = this.$config.opciones.find((x) => x.id == 12);
        if (config) {
          if (config.valor && config.activo) {
            //Imprimimos automaticamente
            this.loading = true;
            try {
              await this.imprimir(this.impresoras[0]);
            } catch (error) {
              alert(error);
            } finally {
              this.loading = false;
            }
          }
        }
      }
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
