<template>
  <v-container fluid fill-height>
    <Loader v-model="loading" />
    <Keyboard v-model="keyboard" />
    <TerminalUI
      @entrar="entrarTerminal"
      :empresa="empresa"
      :cajero="cajero"
      @change_ultimo="ultimoRegister = null"
      :ultimoRegister="ultimoRegister"
      :terminales="terminales"
      :usuario="usuario"
      v-model="terminalDialog"
    />
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card color="grey darken-1" shaped class="login-card justify-center rounded-xl">
          <v-toolbar flat light color="secondary">
            <v-spacer></v-spacer>
            <v-toolbar-title class="white--text text-center">Tootle TPV</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-fab-transition>
              <v-btn
                @click="keyboardMode"
                fab
                large
                absolute
                top
                right
                class="v-btn--example"
              >
                <v-icon v-if="!keyboardSession">mdi-keyboard</v-icon>
                <v-icon v-else>mdi-keyboard-off</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                color="secondary"
                name="login"
                label="Login"
                outlined
                dark
                rounded
                type="text"
                v-model="usuario.usuario"
                @click="showKeyboard"
              ></v-text-field>
              <v-text-field
                color="secondary"
                id="password"
                dark
                name="password"
                label="Password"
                outlined
                rounded
                type="password"
                v-model="usuario.password"
                @click="showKeyboard"
              ></v-text-field>
            </v-form>
            <v-container>
              <v-row>
                <v-btn rounded block dark color="secondary" @click="login">Login</v-btn>
              </v-row>
              <br /><br />
              <v-row>
                <v-btn rounded block dark color="secondary">NIP</v-btn>
              </v-row>
              <br />
              <!-- <v-row>
                <v-col md="6">
                  <v-btn rounded block dark color="primary" @click="admin()">
                    <v-icon left dark> mdi-account-settings </v-icon>
                    Administrador TPV</v-btn
                  >
                </v-col>
                <v-col md="6">
                  <v-btn rounded block dark color="red" @click="youtube()">
                    <v-icon left dark> mdi-youtube </v-icon>
                    Videos</v-btn
                  >
                </v-col>
              </v-row> -->
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Keyboard from "../components/Keyboard.vue";
import Cookies from "js-cookie";
import { POSRegisterFunctions } from "../controllers/POSController";
import {
  Usuario,
  TerminalDialog,
  Session,
  Toast,
  ErrorWeb,
} from "../controllers/DataController.js";
import Loader from "../components/Loader.vue";
import TerminalUI from "../components/TerminalComponent.vue";

export default {
  data() {
    return {
      keyboardSession: false,
      keyboard: {
        visible: false,
        input: null,
      },
      version:
        "Version: " +
        process.env.VUE_APP_RELEASE_NAME +
        " " +
        process.env.VUE_APP_RELEASE_VERSION,
      loading: false,
      terminalDialog: new TerminalDialog(),
      usuario: new Usuario(),
      registerFunctions: new POSRegisterFunctions(),
      ultimoRegister: null,
      nip: {
        isVisible: false,
      },
      terminales: [],
      terminal: "",
      cajero: {
        intCajero: 0,
      },
      empresa: parseInt(this.$config.empresa),
      sucursal: parseInt(this.$config.sucursal),
    };
  },
  components: {
    Loader,
    TerminalUI,
    Keyboard,
  },
  methods: {
    showKeyboard(e) {
      if (this.keyboardSession) {
        this.keyboard.input = e.target;
        this.keyboard.visible = true;
      }
    },
    admin() {
      this.$router.push("Admin/Login");
    },
    youtube() {
      window.location.href = "https://www.youtube.com/channel/UCheNu9ASYwNMPnSayrxnSrQ";
    },
    entrarNIP() {
      this.nip.isVisible = true;
    },
    async entrarTerminal(seleccion) {
      this.terminal = seleccion.terminal;
      this.terminalDialog.isVisible = false;
      this.loading = true;
      try {
        await this.usuario.login_terminal(this.terminal);

        // * MCZ -> Guardamos terminal en cookie
        Cookies.set("POS_TERMINAL", this.terminal, { expires: 5 });

        // * MCZ -> Necesitamos validar los dos registers actuales, el del usuario y el actual de terminal
        const REGISTER_ACTUAL = await this.registerFunctions.register_actual(
          this.empresa,
          this.terminal
        );

        const REGISTER_ACTUAL_USUARIO = await this.usuario.register_actual(
          this.empresa,
          this.terminal
        );

        if (seleccion.apertura) {
          // * MCZ -> El usuario desea abrir caja
          if (REGISTER_ACTUAL.intPOSRegister == 0) {
            // * MCZ -> No existe registro actual, puede abrir caja
            Cookies.set("POS_TIPO", "cajero", { expires: 5 });
            this.$router.push("/Caja/1");
          } else if (
            REGISTER_ACTUAL.intPOSRegister == REGISTER_ACTUAL_USUARIO.intPOSRegister
          ) {
            // * MCZ -> Existe apertura, y es del usuario, lo mandamos directo al main
            Cookies.set("POS_REGISTER_CAJA", REGISTER_ACTUAL_USUARIO.intPOSRegister, {
              expires: 5,
            });
            Cookies.set("POS_TIPO", "cajero", { expires: 5 });
            this.$router.push("/Main");
          } else {
            // * MCZ -> Existe apertura, no es del usuario, no puede abrir, mandamos error y que entre como comandera
            throw (
              "Otro usuario tiene abierta la caja en esta terminal, no puede acceder como CAJERO. Register Actual : " +
              REGISTER_ACTUAL.intPOSRegister +
              " Register Usuario: " +
              REGISTER_ACTUAL_USUARIO.intPOSRegister
            );
          }
        } else {
          // * MCZ -> Version de comandero, no necesita tener abierta caja (usuario), pero necesita un register de terminal abierto
          if (REGISTER_ACTUAL.intPOSRegister == 0)
            throw "La caja de la terminal no ha sido abierta, no se puede acceder";
          else {
            // * MCZ -> Existe apertura, lo mandamos directo al main
            Cookies.set("POS_REGISTER_CAJA", REGISTER_ACTUAL.intPOSRegister, {
              expires: 5,
            });
            if (seleccion.cajero) Cookies.set("POS_TIPO", "cajero", { expires: 5 });
            else Cookies.set("POS_TIPO", "comandero", { expires: 5 });
            this.$router.push("/Main");
          }
        }
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    keyboardMode() {
      this.keyboardSession = !this.keyboardSession;
      let session = new Session();
      session.setKeyboard(this.keyboardSession);
    },
    async login() {
      this.loading = true;
      try {
        // * MCZ -> Logeamos
        await this.usuario.login();
        // * MCZ -> Ya validado guardamos informacion en cookies
        Cookies.set("POS_USER", this.usuario.usuario, { expires: 5 });
        Cookies.set("POS_EMPRESA", this.empresa, { expires: 5 });
        Cookies.set("POS_SUCURSAL", this.sucursal, { expires: 5 });
        // * MCZ -> Traemos las terminales que puede usar y la informacion del TPV del Usuario
        this.terminales = await this.usuario.terminales(this.usuario.usuario);
        this.cajero = await this.usuario.cajero(this.usuario.usuario);
        this.ultimoRegister = await this.usuario.ultimo_register(new Session());
        this.terminalDialog.isVisible = true;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.keyboardSession = new Session().keyboard;
  },
};
</script>

<style>
.login-card {
  opacity: 0.8;
}
/* #app {
  background-image: url("https://i1.wp.com/www.horatope.com/wp-content/uploads/2016/11/square-biz-in-a-box.jpg?ssl=1");
  background-size: cover;
  overflow: hidden;
} */
</style>
