<template>
  <v-dialog v-model="localValue.isVisible" width="750">
    <Loader v-model="loading" />
    <v-card>
      <v-toolbar color="primary" dark>
        <v-btn icon dark @click="localValue.isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cancelar / Devolver Detalle</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Informacion</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      readonly
                      outlined
                      label="Cantidad"
                      :value="localValue.posdet.dblCantidad"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      readonly
                      outlined
                      label="Nombre"
                      :value="localValue.posdet.strNombre"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Cancelacion</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.usuario"
                        :rules="[() => !!usuario.usuario || 'Llene este campo']"
                        append-icon="mdi-account"
                        label="Usuario"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        dense
                        v-model="usuario.password"
                        :rules="[() => !!usuario.password || 'Llene este campo']"
                        append-icon="mdi-shield-lock"
                        type="password"
                        label="Password"
                        outlined
                        required
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-radio-group ref="tipo" v-model="localValue.tipo" row>
                        <v-radio label="Devolucion" :value="8"></v-radio>
                        <v-radio label="Cancelacion" :value="9"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        :items="motivos"
                        outlined
                        ref="motivo"
                        item-text="strNombre"
                        item-value="intPOSMotivoCancelacion"
                        label="Motivo"
                        v-model="localValue.motivo"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        ref="comentario"
                        outlined
                        label="Comentario"
                        v-model="localValue.comentario"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" dark block @click="guardar()"> Guardar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Usuario, Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSDetFunctions } from "../controllers/POSController";

export default {
  props: {
    motivos: {
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      loading: false,
      session: new Session(),
      usuario: new Usuario(),
      detFunctions: new POSDetFunctions(),
    };
  },
  components: {
    Loader,
  },
  methods: {
    async guardar() {
      this.loading = true;
      try {
        if (
          this.localValue.motivo == null ||
          this.localValue.motivo == "" ||
          this.localValue.motivo == 0
        ) {
          this.$refs.motivo.focus();
          throw "Seleccione un motivo";
        }
        if (this.localValue.comentario == null || this.localValue.comentario == "") {
          this.$refs.comentario.focus();
          throw "Agregue un comentario";
        }
        if (this.localValue.tipo == null) {
          this.$refs.tipo.focus();
          throw "Seleccione un tipo";
        }

        //*Validamos usuario
        const validacion = await this.usuario.validate_cancelacion();
        if (!validacion) throw "No se pudo validar el usuario, no tiene permisos";

        this.localValue.posdet.strUsuarioMod = this.session.usuario;
        this.localValue.posdet.strUsuarioCancelacion = this.session.usuario;
        this.localValue.posdet.strComentarioCancelacion = this.localValue.comentario;
        this.localValue.posdet.intPOSMotivoCancelacion = this.localValue.motivo;
        this.localValue.posdet.intCancelacionDevolucion = this.localValue.tipo;
        this.localValue.posdet.intEstatus = this.localValue.tipo;

        await this.detFunctions.cancelacion(this.localValue.posdet);
        //*Limpiamos
        this.localValue.isVisible = false;
        this.usuario = new Usuario();
        this.$emit("guardar");
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
