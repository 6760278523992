<template>
  <div>
    <Loader v-model="loading" />

    <v-dialog v-model="edit" persistent max-width="290">
      <v-card>
        <v-card-title> Editar </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field outlined 
                  v-model="ordenFunctions.strReferencia"
                  label="Referencia">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" text @click="edit = false"> Cancelar </v-btn>
          <v-btn color="primary" text @click="editConfirm()"> Aceptar </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Corte de Vouchers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-btn x-large block color="primary" @click="confirmar()">
                        Confirmar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-data-table :headers="headers" :items="results">
                        <template v-slot:[`item.dblImporte`]="{ item }">
                          {{ isMoney(item.dblImporte) }}
                        </template>
                        <template v-slot:[`item.dblTotal`]="{ item }">
                          {{ isMoney(item.dblTotal) }}
                        </template>
                        <template v-slot:[`item.strNombre`]="{ item }">
                          {{ item.strNombre.substring(3, item.lenght) }}
                        </template>
                        <template v-slot:[`item.intPOSEncPago`]="{ item }">
                          <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";
import { isMoney } from "../js/utilities";

export default {
  data() {
    return {
      session: new Session(),
      loading: false,
      ordenFunctions: new POSEncFunctions(),
      edit: false,
      confirm: false,
      headers: [
        { text: "Orden", value: "intOrden" },
        { text: "Total Orden", value: "dblTotal" },
        { text: "Tipo", value: "strNombre" },
        { text: "Importe Pago", value: "dblImporte" },
        { text: "Referencia", value: "strReferencia" },
        { text: "Acciones", value: "intPOSEncPago" },
      ],
      results: [],
    };
  },
  components: {
    Loader,
  },
  methods: {
    isMoney: isMoney,
    async confirmar() {
      this.loading = true;
      try {
        this.results = await this.ordenFunctions.confirmaVouchers();
        this.$toast.success("Se han confirmado los pagos en tarjeta", new Toast());
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.ordenFunctions.intPOSEnc = item.intPOSEnc;
      this.ordenFunctions.intPOSEncPago = item.intPOSEncPago;
      this.ordenFunctions.strReferencia = item.strReferencia;
      this.edit = true;
    },
    async editConfirm() {
      this.loading = true;
      try {
        this.ordenFunctions.intEmpresa = this.session.empresa;
        this.ordenFunctions.strUsuarioMod = this.session.usuario;
        this.ordenFunctions.intPOSRegister = this.session.register;
        await this.ordenFunctions.actualizaVouchers();
        this.ordenFunctions = new POSEncFunctions();
        this.$toast.success("Registro actualizado con exito", new Toast());
        this.results = await this.ordenFunctions.vouchers();
        this.edit = false;
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.push("/Main");
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.results = await this.ordenFunctions.vouchers();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
