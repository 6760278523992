<template>
  <div>
    <Loader v-model="loading" />
    <v-app-bar app>
      <v-app-bar app color="primary" dark>
        <v-icon @click="goBack">mdi-keyboard-backspace</v-icon>
        <v-spacer></v-spacer>
        <v-toolbar-title>Administrador de Tickets</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon>mdi-account-circle</v-icon> {{ session.usuario.toUpperCase() }}
      </v-app-bar>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title> Informacion </v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="version"
                        :items="versiones"
                        label="Tipo"
                        item-text="name"
                        item-value="value"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="date"
                        label="Fecha Inicio"
                        outlined
                        v-model="info.fecha_inicio"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        type="date"
                        label="Fecha Final"
                        outlined
                        v-model="info.fecha_fin"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        placeholder="Todos"
                        :items="usuarios"
                        v-model="info.usuario"
                        label="Usuario"
                        outlined
                      >
                      </v-select>
                    </v-col>
                    <v-col md="1">
                      <v-btn x-large block color="primary" @click="buscar()">
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Resultados</v-card-title>
              <v-card-text>
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-data-table :headers="headers" :items="results">
                        <template v-slot:[`item.intEstatus`]="{ item }">
                          {{ getEstatus(item.intEstatus) }}
                        </template>
                        <template v-slot:[`item.dblSubtotal`]="{ item }">
                          {{ isMoney(item.dblSubtotal) }}
                        </template>
                        <template v-slot:[`item.dblTotal`]="{ item }">
                          {{ isMoney(item.dblTotal) }}
                        </template>
                        <template v-slot:[`item.strUsuarioPOS`]="{ item }">
                          {{ item.strUsuarioPOS.toUpperCase() }}
                        </template>
                        <template v-slot:[`item.intPOSTipoServicio`]="{ item }">
                          {{ getServicio(item.intPOSTipoServicio) }}
                        </template>
                        <template v-slot:[`item.datFecha`]="{ item }">
                          {{ toDateString(item.datFecha, 2) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import { Session, Toast, ErrorWeb } from "../controllers/DataController.js";
import { POSEncFunctions } from "../controllers/POSController";
import { isMoney, toDateString } from "../js/utilities";
import { Data } from "../controllers/DataController.js";
import moment from "moment";

export default {
  data() {
    return {
      session: new Session(),
      dataFunctions: new Data(),
      ordenFunctions: new POSEncFunctions(),
      usuarios: [],
      versiones: [
        { value: 1, name: "Ventas" },
        { value: 2, name: "Salidas de Caja" },
        { value: 3, name: "Arqueos obligatorios" },
      ],
      version: 1,
      info: {
        usuario: "",
        fecha_inicio: moment().format("yyyy-MM-DD"),
        fecha_fin: moment().format("yyyy-MM-DD"),
      },
      loading: false,
      headers: [
        { text: "Folio", value: "intFolio" },
        { text: "Fecha", value: "datFecha" },
        { text: "Orden", value: "intOrden" },
        { text: "Estatus", value: "intEstatus" },
        { text: "Tipo", value: "intPOSTipoServicio" },
        { text: "Referencia", value: "strMesa" },
        { text: "Subtotal", value: "dblSubtotal" },
        { text: "Total", value: "dblTotal" },
        { text: "Usuario", value: "strUsuarioPOS" },
      ],
      results: [],
    };
  },
  components: {
    Loader,
  },
  methods: {
    isMoney: isMoney,
    toDateString: toDateString,
    getEstatus(intEstatus) {
      if (intEstatus == 2) {
        return "PREPARACION";
      } else if (intEstatus == 4) {
        return "PAGADA";
      } else if (intEstatus == 8 || intEstatus == 9) {
        return "CANCELADA";
      } else return intEstatus;
    },
    goBack() {
      this.$router.push("/Main");
    },
    getServicio(servicio) {
      if (servicio == 1) return "MESA";
      else if (servicio == 2) return "DOMICILIO";
      else if (servicio == 3) return "LLEVAR";
    },
    async buscar() {
      this.loading = true;
      try {
        this.results = await this.ordenFunctions.administradorTicket(
          this.session.empresa,
          this.version,
          this.info
        );
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    let session = new Session();
    if (!session.usuario) {
      this.$router.push("/");
    } else {
      this.loading = true;
      try {
        this.usuarios = await this.dataFunctions.loadUsuariosOrdenes();
      } catch (e) {
        this.$toast.error(ErrorWeb(e), new Toast());
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style></style>
